import React from 'react'
import ValidateSignUp from '../MainSignUp/ValidateSignUp'

function Main() {
  return (
    <>
      <div className="full_bg">
        <div className="container"><ValidateSignUp/></div>
      </div>
    </>
  )
}

export default Main
