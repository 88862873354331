import React from 'react'
import Header from '../Header/Main'
import Need from '../MainHome/Need'
import Review from '../MainTestimonial/Review'
import ServiceReview from '../MainTestimonial/ServiceReview'
import Bredcom from '../Bredcom/Main'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom same={"What Our Client Says"} title={"Home"} subtitle={"Testimonials"}/>
      </div>
      <Review />
      <ServiceReview />
      <Need />
    </>
  )
}

export default Main
