import {WebinarHeader} from "./header";
import '../../assets/css/webinar.css'
import WebinarBody from "./body";

export default function Webinar() {
  return (
    <div className={'webinar-body'}>
      <div className={'max-720'}>
        <WebinarHeader/>
        <WebinarBody/>
      </div>
    </div>
  )
}