import React from 'react'
import Header from '../Header/Main'
import Pricing from '../MainHome/Pricing'
import FaqBlock from '../MainHome/FaqBlock'
import Need from '../MainHome/Need'
import Bredcom from '../Bredcom/Main'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom same={"Nuestros precios"} title={"Inicio"} subtitle={"Nuestros precios"}/>
      </div>
      <Pricing />
      <Need />

    </>
  )
}

export default Main
