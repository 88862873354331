export default function CardBox({children, image, title}) {
  return (<div className="card mb-3 card-box" style={{color: '#674492', borderRadius: '15px'}}>
    <div className="row">
      <div className="col-4">
        <img className={'m-3'} src={image} width={'100%'}/>
      </div>
      <div className="col-8 text-left">
        <div className={'card mt-3 mr-4 pl-2'} style={{background: '#674492', color: 'white', borderRadius: '15px'}}>
          <bold>{title}</bold>
        </div>
        {children}
      </div>
    </div>
  </div>)
}