import acceso from "../../assets/images/webinar/acceso-gratuito.png";
import check from "../../assets/images/webinar/icon-check.png";
import diferente from "../../assets/images/webinar/nova-diferente.png";
import waves from "../../assets/images/webinar/waves.png";
import eficiencia from "../../assets/images/webinar/eficiencia-operativa.png";
import experiencia from "../../assets/images/webinar/experiencia-al-cliente.png";
import toma from "../../assets/images/webinar/toma-de-desiones.png";
import masServicios from "../../assets/images/webinar/mas-servicios-mas-ingresos.png";
import gift from "../../assets/images/webinar/acceso-gratuito.png";
import CardBox from "./cardBox";

export default function WebinarBody() {
  return (
    <div>
      <div className={'row'}>
        <div className={'col-12 px-3'}>
          <div className={'text-center mt-3'}>
            <bold>¡Gestiona tu ISP de forma inteligente con NOVA!</bold>
          </div>

          <div className={'text-center mt-3 w-75 mx-auto'}>
            <p>Sabemos que estás cansado de herramientas que solo implican y ralentizan tu trabajo.</p>
            Nuestro <bold>software todo en uno</bold>, potenciado por inteligencia artificial, centraliza y optimiza
            cada
            proceso para que administres tu ISP de manera eficiente y sin complicaciones.
          </div>

          <div className={'text-center mt-3 p-4 '}>
            <iframe className={'video-container'}
                    src="https://www.youtube.com/embed/Ms3BWkGE5rM?si=IKeh_pe633GrXPXe&amp;controls=0"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen></iframe>
          </div>
          <div className={'text-center mt-3 px-3 mb-3'}>
            ¿Quieres conocer a detalle cómo NOVA puede transformar la gestión de tu ISP?
          </div>
        </div>
      </div>
      <div className={'row webinar-body-row-register'}>
        <div className={'col-12'}>
          <div className={'text-center mt-3'}>
            <div className={'cursor-pointer d-inline-block button-asset mr-3 font-weight-bold'}><a
              className={'text-white'} href={'https://forms.gle/Dn4mmk9jQN14beGw7'} target={'_blank'}>¡PULSA AQUÍ!</a>
            </div>
            y asegura tu lugar en nuestro webinar
          </div>
          <div className={'text-center mt-3'}>
            <img className={''} src={acceso} alt="automatiza tareas" width={100}/>
            Incluye ACCESO GRATUITO a nuestro software para 50 usuarios
          </div>
        </div>
      </div>
      <div className={'row mt-5 px-2'}>
        <div className={'col-12 col-md-6'}>
          <img src={diferente} className={'w-100'} alt="diferente"/>
        </div>
        <div className={'col-12 col-md-6'}>
          <h5>
            <bold>¿Qué hace a NOVA diferente?</bold>
          </h5>
          <li>
            <ul>
              <div className={'row'}>
                <div className={'col-1'}>
                  <img src={check} width={30}/>
                </div>
                <div className={'col-11'}>
                  <bold>IA INTEGRADA:</bold>
                  <p>Ahorra en contratación de empleados con una IA que atiende a tus clientes 24/7</p>
                </div>
              </div>
            </ul>
            <ul>
              <div className={'row'}>
                <div className={'col-1'}>
                  <img src={check} width={30}/>
                </div>
                <div className={'col-11'}>
                  <bold>INTEGRACIÓN PASARELAS DE RECAUDO:</bold>
                  <p>Contamos con conexión con la mayoría de pasarelas de pago del mercado, como Wompi, Mercadopago,
                    E-payco, Payvalida, Super Pagos, Combopay entre otras</p>
                </div>
              </div>
            </ul>
            <ul>
              <div className={'row'}>
                <div className={'col-1'}>
                  <img src={check} width={30}/>
                </div>
                <div className={'col-11'}>
                  <bold>FACTURACIÓN ELECTRÓNICA:</bold>
                  <p>Cumple con normativas, simplifica la gestión de pagos y accede a métricas y reportes financieros en
                    tiempo real</p>
                </div>
              </div>
            </ul>
            <ul>
              <div className={'row'}>
                <div className={'col-1'}>
                  <img src={check} width={30}/>
                </div>
                <div className={'col-11'}>
                  <bold>GESTIÓN DE PERSONAL:</bold>
                  <p>Optimiza la administración de nómina, pagos y programación de instalaciones con facilidad</p>
                </div>
              </div>
            </ul>
          </li>
        </div>
      </div>
      <div className={''}>
        <img src={waves} width={'100%'}/>
        <div className={'wave-container'}>
          <div className={'text-center'}>
            <h2>Beneficios que transformarán tu ISP</h2>
            <p>Descubre los grandes beneficios que tendras con NOVA</p>
            <div className="row justify-content-center px-2">
              <div className="col-12 col-md-5">
                <CardBox image={eficiencia} title={'Eficiencia operativa:'}>
                  <div className={'card-box-item'}>
                    <bold>- Automatiza procesos críticos</bold>
                    como cortes, reconexiones y facturación
                  </div>
                  <div className={'card-box-item'}>
                    <bold>- Ahorra tiempo y reduce errores</bold>
                    en todas las areas de tu negocio
                  </div>
                  <div className={'card-box-item'}>
                    <bold>- Optimiza la productividad</bold>
                    de tu equipo y mejora la rentabilidad
                  </div>
                </CardBox>
              </div>
              <div className="col-12 col-md-5">
                <CardBox image={experiencia} title={'Experiencia de Cliente:'}>
                  <div className={'card-box-item'}>
                    <bold>- Atención personalizada</bold>
                    con herramientas avanzadas como chatbots inteligentes
                  </div>
                  <div className={'card-box-item'}>
                    <bold>- Gestión precisa</bold>
                    de contratos, y soporte técnico eficiente
                  </div>
                </CardBox>
              </div>
            </div>
            <div className="row justify-content-center px-2">
              <div className="col-12 col-md-5">
                <CardBox image={toma} title={'Toma de decisiones:'}>
                  <div className={'card-box-item'}>
                    <bold>- Análisis de datos</bold>
                    en tiempo real para identificación de problemas y oportunidades
                  </div>
                  <div className={'card-box-item'}>
                    <bold>- Optimización de estrategias</bold>
                    con informes detallados sobre soporte, retención y ventas.
                  </div>
                  <div className={'card-box-item'}>
                    <bold>- Toma de decisiones estratégicas</bold>
                    basadas en datos confiables
                  </div>
                </CardBox>
              </div>
              <div className="col-12 col-md-5">
                <CardBox image={masServicios} title={'Más servicios, Más ingresos:'}>
                  <div className={'card-box-item'}>
                    <bold>- Ofrece servicios adicionales</bold>
                    como IPTV, telefonía, canales premium, financiación de equipos
                  </div>
                  <div className={'card-box-item'}>
                    <bold>- Aumenta tus ingresos</bold>
                    con promociones inteligentes y personalizadas
                  </div>
                </CardBox>
              </div>
            </div>
          </div>
          <div className={'row justify-content-center text-center px-2'}>
            <div className={'col-12 col-md-5'}>
              <div className={'card card-transparent'}>
                <bold>Estos son solo algunos de nuestros beneficios</bold>
                <p>¿Quieres conocer todos los detalles y descubrir como nuestro software puede impulsar la gestión de tu
                  ISP al máximo?</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'text-center mt-3 mb-3'}>
        <div className={'cursor-pointer d-inline-block button-asset mr-3 font-weight-bold'} style={{color: 'white'}}>
          <a className={'text-white'} href={'https://forms.gle/Dn4mmk9jQN14beGw7'} target={'_blank'}>
            <bold>¡PULSA AQUÍ!</bold>
          </a>
        </div>
        <bold>y asegura tu lugar en nuestro webinar</bold>
      </div>
      <div className={'text-center mt-3 mb-3 body-end'}>
        <img src={gift} width={100}/>
        <bold>Inicia de manera GRATUITA hasta con 50 usuarios</bold>
      </div>
      <div className={'row justify-content-center text-center pb-4 p-3'}>
        <div className={'col-12 col-md-6'}>
          <iframe
                  src="https://www.youtube.com/embed/Ym0N6uJN0lo?si=IKeh_pe633GrXPXe&amp;controls=0"
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen></iframe>
        </div>
        <div className={'col-12 col-md-6'}>
          <iframe
                  src="https://www.youtube.com/embed/kCyntf_XgBk?si=IKeh_pe633GrXPXe&amp;controls=0"
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen></iframe>
        </div>
        <div className={'col-12 col-md-6'}>
          <iframe
                  src="https://www.youtube.com/embed/l_vcZrH_924?si=IKeh_pe633GrXPXe&amp;controls=0"
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen></iframe>
        </div>
        <div className={'col-12'}>
          <bold>ISPs como el tuyo ya están creciendo con NOVA</bold>
        </div>
      </div>
      <div className={'row justify-content-center text-center webinar-partners'}>
        <div className={'col-8 mt-5'}>
          <img src={'https://servired.co/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.8c1ca95d.png&w=256&q=75'}/>
          <p>Con Nova, automatizamos el 90% de nuestros procesos y aumentamos la retención de clientes en un 30%. Ahora
            podemos enfocarnos en hacer crecer nuestro negocio</p>
        </div>
      </div>
      <div className={'row mt-5 mb-5'}></div>
      <div className={'row mt-5 mb-5'}></div>
    </div>
  )
}