import React from 'react'
import Header from '../Header/Main'
import ServiceDetail from '../MainServiceDetail/ServiceDetailss'
import Need from '../MainHome/Need'
import Bredcom from '../Bredcom/Second'

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom same={"Software Development"} title={"Home"} subtitle={"Services"}/>
      </div>
      <ServiceDetail />
      <Need />

    </>
  )
}

export default Main
