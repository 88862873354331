import React, { useState } from 'react'


const faqItems = [
  {
    id: "1",
    question: "Primeros pasos",
    answer:
      "No es necesario empezar de cero, puedes importar tus clientes directamente de la OLT, Excel, o desde otro software de gestión",
  },
  {
    id: "2",
    question: "¿Cuales son los medios de pago?",
    answer:
      "Contamos con multiples medios de pago en Colombia, PSE, Tarjeta de credito, Pago en corresponsales bancarios.",
  }
];


function FaqBlock() {

  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  return (
    <>
      <section id="faqBlock" className="row_am faq_section">
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        >
          <h2>
            <span>AYUDA</span> - Manuales
          </h2>
          <p>
            Encuentra los manuales para el uso de nuestra herramienta
          </p>
        </div>
        <div className="faq_panel">
          <div className="accordion" id="accordionExample">
            {faqItems.map((item, index) => (
              <div
                className="card"
                data-aos="fade-up"
                data-aos-duration="1500"
                key={item.id}
              >
                <div className="card-header" id={`heading${index}`}>
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className={`btn btn-link ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => handleAccordionClick(index)}
                    >
                      <i
                        className={`icon_faq icofont-plus ${
                          activeIndex === index ? "active" : ""
                        }`}
                      ></i>{" "}
                      {item.question}
                    </button>
                  </h2>
                </div>
                <div
                  id={`collapse${index}`}
                  className={`collapse ${
                    activeIndex === index ? "show" : ""
                  }`}
                  aria-labelledby={`heading${index}`}
                  data-parent="#accordionExample"
                >
                  <div className="card-body">{item.answer}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default FaqBlock
