import React from 'react'
import SignIn from '../MainSignIn/SignIn'

function Main() {
  return (
    <>
      <SignIn />
    </>
  )
}

export default Main
