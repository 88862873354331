import logoWhite from '../../assets/images/nova-white.png'
import automatiza from '../../assets/images/webinar/automatiza-tareas.png'
import optimiza from '../../assets/images/webinar/optimiza-tiempo.png'
import enfocate from '../../assets/images/webinar/enfocate.png'

export function WebinarHeader() {
  return (
    <div className={'row'}>
      <div className={'title-purple col-12'}>
        <a className={'text-white'} href={'https://forms.gle/Dn4mmk9jQN14beGw7'} target={'_blank'}>
          <bold>Pulsa aquí</bold>
          y registrate en nuestro <bold>webinar // GRATIS</bold> demo del software
        </a>
      </div>

      <div className={'tittle-banner col-12'}>
        <img className={'pt-5 mt-2 mb-3'} src={logoWhite} alt="logo nova" width={250}/>
        <h1 className={'font-weight-bolder p-4'}>¡EL FUTURO DE TU ISP</h1>
        <h1 className={'font-weight-bolder'}>COMIENZA HOY!</h1>
        <h5 className={'px-3'}>Regístrate a nuestro <bold>webinar gratuito</bold> y descubre</h5>
        <h5>cómo NOVA puede <bold>transformar tu
          negocio</bold></h5>
        <div className={'row'}>
          <div className={'col-4'}>
            <div className={'d-block'}>
              <img className={''} src={automatiza} alt="automatiza tareas" width={100}/>
            </div>
            <bold>Automatiza</bold>
            <div>tareas operativas</div>
          </div>
          <div className={'col-4'}>
            <div className={'d-block'}>
              <img className={''} src={optimiza} alt="automatiza tareas" width={100}/>
            </div>
            <bold>Optimiza</bold>
            tiempo y recursos.
          </div>
          <div className={'col-4'}>
            <div className={'d-block'}>
              <img className={''} src={enfocate} alt="automatiza tareas" width={100}/>
            </div>
            <bold>Enfócate</bold>
            en lo que realmente importa
          </div>
        </div>
        <div className={'row mx-2'}>
          <div className={'col-12 justify-content-center pb-3 mt-2'}>
            <div
              className={'col-12 banner-bottom px-3 py-1'}>
              <bold>¡Atraer más clientes y aumentar tus ingresos!</bold>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}