import React from 'react'
import Header from '../Header/Main'
import FaqBlock from '../MainFAQ/Faqblock'
import Need from '../MainHome/Need'
import Bredcom from '../Bredcom/Main' 

function Main() {
  return (
    <>
      <div className="inner_page_block">
        <Header />
        <Bredcom same={"Ayuda"} title={"Inicio"} subtitle={"Ayuda"}/>
      </div>
      <FaqBlock />
      <Need />
    </>
  )
}

export default Main
