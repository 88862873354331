import React from 'react'
import Header from '../Header/Main'
import Banner from '../MainHome/Bannerhome'
import Trusted from '../MainHome/Trusted'
import Why from '../MainHome/Why'
import Analyze from '../MainHome/Analyze'
import Collaborate from '../MainHome/Collaborate'
import Communication from '../MainHome/Communication'
import AdvanceFeature from '../MainHome/AdvanceFeature'
import PowerfulSolution from '../MainHome/PowerfulSolution'
import Integration from '../MainHome/Integration'
import Customer from '../MainHome/Customer'
import Pricing from '../MainHome/Pricing'
import FaqBlock from '../MainHome/FaqBlock'
import Interface from '../MainHome/Interface'
import FreeTrial from '../MainHome/FreeTrial'
import LatestStory from '../MainHome/LatestStory'
import Need from '../MainHome/Need'

function Main() {
  return (
    <>
      <div className="top_home_wraper">
        <Header />
        <Banner />
      </div>
      <Trusted />
      <Why />
      <Analyze />
      <Collaborate />
      <Communication />
      {/*
      <AdvanceFeature />
      <PowerfulSolution />
      <Integration />
      <Customer />
      <Pricing />
      <FaqBlock />
      <Interface />
      <FreeTrial />
      <LatestStory />
      */}
      <Need />
    </>
  )
}

export default Main
